//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { v4 as uuidv4 } from 'uuid'
import { esaEncryptData, esaDecryptData } from '~/plugins/secret'
import { zenDeskFunc } from '@/plugins/utils'

export default {
  props: {
    setContactModalType: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      geoInfo: null,
      locales: [],
      selectedLang: 'en',
      dropDownDisplayText: 'EN',
      pagsmile_white: require('@/assets/img/pagsmile.svg'),
      pagsmile_color: require('@/assets/img/pagsmile-color.svg'),
      interval: null,
      currentPage: null,
      errorPage: false,
      overMenu: false,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : null,
      isLargeScreen:
        typeof window !== 'undefined'
          ? window.matchMedia('(min-width: 1024px)').matches
          : false,
      solutionsHover: false,
      companyHover: false,
      contactHover: false,
      globeHover: false,
      sidebarActive: false,
      iconsHover: [false, false, false],
      mobileMenu: [
        {
          title: 'header.menu-item-1.title',
          list: [
            {
              type: 'title',
              text: 'header.menu-item-1.products.title'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.products.item1',
              linkType: 'internal',
              link: '/solutions/payment-processing'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.products.item2',
              linkType: 'internal',
              link: '/solutions/pagsmile-payout'
            },
            {
              type: 'title',
              text: 'header.menu-item-1.features.title'
            },
            // {
            //   type: 'link',
            //   text: 'header.menu-item-1.features.item1',
            //   linkType: 'external',
            //   link: 'https://docs.pagsmile.com/'
            // },
            {
              type: 'link',
              text: 'header.menu-item-1.features.item2',
              linkType: 'internal',
              link: '/products-services/local-payments'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.features.item3',
              linkType: 'internal',
              link: '/solutions/pagsmile-dashboard'
            },
            {
              type: 'title',
              text: 'header.menu-item-1.resources.title'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.resources.item1',
              linkType: 'internal',
              link: '/country-guides'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.resources.item2',
              linkType: 'internal',
              link: '/industry/global-companies'
            },
            {
              type: 'link',
              text: 'header.menu-item-1.resources.item3',
              linkType: 'internal',
              link: '/solutions/marketing-localization'
            }
          ]
        },
        {
          title: 'header.menu-item-2.title',
          list: [
            {
              type: 'title',
              text: 'header.menu-item-2.col2.title'
            },
            {
              type: 'link',
              text: 'header.menu-item-2.col1.item1',
              linkType: 'internal',
              link: '/about-us'
            },
            {
              type: 'link',
              text: 'header-menu-events',
              linkType: 'internal',
              link: '/about-us/events'
            },
            /* {
              type: 'link',
              text: 'header.menu-item-2.col1.item2',
              linkType: 'internal',
              link: '/about-us/pressroom'
            }, */
            {
              type: 'link',
              text: 'header.menu-item-2.col2.item1',
              linkType: 'internal',
              link: '/about-us/careers'
            },
            {
              type: 'link',
              text: 'header.menu-item-2.col2.item2',
              linkType: 'internal',
              link: '/about-us/legal'
            },
            {
              type: 'link',
              text: 'header.menu-item-2.col3.item1',
              linkType: 'internal',
              link: '/about-us/blog'
            },
            {
              type: 'link',
              text: 'header.menu-item-2.col3.item2',
              linkType: 'internal',
              link: '/about-us/faq'
            }
          ]
        },
        {
          title: 'footer.about.contact',
          list: [
            {
              type: 'link',
              text: 'multi-contact-form-type-6',
              linkType: 'external',
              link: 'https://pagsmilesupport.zendesk.com/hc/pt-br/requests/new?ticket_form_id=28202262057492'
            }
          ]
          // list: [
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 1,
          //     text: 'multi-contact-form-type-1'
          //   },
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 2,
          //     text: 'multi-contact-form-type-2'
          //   },
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 3,
          //     text: 'multi-contact-form-type-3'
          //   },
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 4,
          //     text: 'multi-contact-form-type-4'
          //   },
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 5,
          //     text: 'multi-contact-form-type-5'
          //   },
          //   {
          //     type: 'clickAction',
          //     action: this.setContactModalType,
          //     params: 6,
          //     text: 'multi-contact-form-type-6'
          //   },
          //   {
          //     type: 'link',
          //     text: 'footer.integrations.support',
          //     linkType: 'external',
          //     link: ''
          //   }
          // ]
        },
        {
          title: 'header.menu-item-lang',
          list: [
            {
              type: 'lang-switch',
              text: 'English',
              code: 'en'
            },
            {
              type: 'lang-switch',
              text: 'Português',
              code: 'pt'
            },
            {
              type: 'lang-switch',
              text: 'Español',
              code: 'es'
            },
            {
              type: 'lang-switch',
              text: '中文',
              code: 'zh'
            }
          ]
        }
      ],
      mobileActive: [],
      notification: {
        enabled: false,
        title: 'Check out our participation in iFX',
        description:
          'iFX EXPO is the leading conference connecting top-level executives in online trading, financial services and fintech from around the world.',
        link: '/about-us/events/1',
        closed: false
      },
      isDev: process.env.ENVIRONMENT === 'DEV',
      livechatGroup: 23
    }
  },
  computed: {
    getSupportLink () {
      return `https://*.lc.chat/8514118/${this.livechatGroup}`
    },
    getCalendarIcon () {
      const { iconsHover, overMenu, currentPage } = this || {}
      if (!iconsHover[0]) {
        if (currentPage !== 'blog' && !overMenu) {
          // home/v3-home/events-white.svg
          return require('@/assets/img/home/v3-home/events-white.svg')
        }
        if (currentPage === 'blog' || (currentPage !== 'blog' && overMenu)) {
          return require('@/assets/img/home/v3-home/events-color.svg')
        }
        return require('@/assets/img/home/v3-home/events-white.svg')
      }
      return require('@/assets/img/home/v3-home/events-color.svg')
    },
    getLanguageIcon () {
      const { iconsHover, overMenu, currentPage } = this || {}
      if (!iconsHover[1]) {
        if (currentPage !== 'blog' && !overMenu) {
          return require('@/assets/img/home/v3-home/lang-picker-white.svg')
        }
        if (currentPage === 'blog' || (currentPage !== 'blog' && overMenu)) {
          return require('@/assets/img/home/v3-home/lang-picker-color.svg')
        }
        return require('@/assets/img/home/v3-home/lang-picker-white.svg')
      }
      return require('@/assets/img/home/v3-home/lang-picker-color.svg')
    },
    getUserIcon () {
      const { iconsHover, overMenu, currentPage } = this || {}
      if (!iconsHover[2]) {
        if (currentPage !== 'blog' && !overMenu) {
          return require('@/assets/img/home/v3-home/user-white.svg')
        }
        if (currentPage === 'blog' || (currentPage !== 'blog' && overMenu)) {
          return require('@/assets/img/home/v3-home/user-color.svg')
        }
        return require('@/assets/img/home/v3-home/user-white.svg')
      }
      return require('@/assets/img/home/v3-home/user-color.svg')
    }
  },
  watch: {
    isLargeScreen (value) {
      if (value) {
        this.sidebarActive = false
      }
    },
    sidebarActive (value) {
      if (!value) {
        this.mobileActive = []
      }
    }
  },
  mounted () {
    this.locales = this.getLocales()
    this.getCurrentLang()
    this.$nuxt.$on('currentPage', e => this.changePage(e))
    this.$nuxt.$on('errorPage', (e) => {
      this.errorPage = e
    })
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.$nuxt.$on('updateGeoInfo', value => this.setGeoInfo(value))
    /* Livechat group */
    if (this.$i18n.locale === 'en' && this.livechatGroup !== '28') {
      this.livechatGroup = '28'
    } else if (this.$i18n.locale === 'pt' && this.livechatGroup !== '23') {
      this.livechatGroup = '23'
    } else if (this.$i18n.locale === 'es' && this.livechatGroup !== '58') {
      this.livechatGroup = '58'
    } else if (this.$i18n.locale === 'zh' && this.livechatGroup !== '28') {
      this.livechatGroup = '28'
    }
    if (!this.isDev) {
      /* remove contact menu since is not yet available */
      this.mobileMenu.splice(2, 1)
    }
    /* update support link from mobile menu */
    if (
      typeof this.mobileMenu[2] !== 'undefined' &&
      this.mobileMenu[2].title === 'footer.about.contact'
    ) {
      if (typeof this.mobileMenu[2].list[6] !== 'undefined') {
        this.mobileMenu[2].list[6].link = this.getSupportLink
      }
    }

    // 顶部提示消息
    setTimeout(async () => {
      await this.fetchEvent()
    }, 0)
  },
  beforeUpdate () {
    this.$nuxt.$on('currentPage', e => this.changePage(e))
    this.$nuxt.$on('changeLangHeader', value => this.setLang(value))
    this.$nuxt.$on('errorPage', (e) => {
      this.errorPage = e
    })
    this.$nuxt.$on('updateGeoInfo', value => this.setGeoInfo(value))
    /* Livechat group */
    if (this.$i18n.locale === 'en' && this.livechatGroup !== '28') {
      this.livechatGroup = '28'
    } else if (this.$i18n.locale === 'pt' && this.livechatGroup !== '23') {
      this.livechatGroup = '23'
    } else if (this.$i18n.locale === 'es' && this.livechatGroup !== '58') {
      this.livechatGroup = '58'
    } else if (this.$i18n.locale === 'zh' && this.livechatGroup !== '28') {
      this.livechatGroup = '28'
    }
    /* update support link from mobile menu */
    if (
      typeof this.mobileMenu[2] !== 'undefined' &&
      this.mobileMenu[2].title === 'footer.about.contact'
    ) {
      if (typeof this.mobileMenu[2].list[6] !== 'undefined') {
        this.mobileMenu[2].list[6].link = this.getSupportLink
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.interval)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.windowWidth = window.innerWidth
        this.isLargeScreen = window.matchMedia('(min-width: 1024px)').matches
      }, 500)
    },
    getCurrentLang () {
      const locale = this.$i18n.locale || 'en'
      const locales = this.$i18n.locales || []
      if (locale !== null) {
        this.selectedLang = locale
        if (locales && locales.length) {
          this.dropDownDisplayText = (
            locales.filter(item => item.code === locale)[0].code || ''
          ).toUpperCase()
          return
        }
        this.dropDownDisplayText = 'EN'
      }
    },
    getLocales () {
      const locales = this.$i18n.locales
      const getLocales = []
      if (locales.length) {
        locales.forEach((locale) => {
          getLocales.push({ code: locale.code, name: locale.name })
        })
      }
      return getLocales
    },
    onChangeLang (event, value) {
      if (value && value.code === 'pt') {
        window.location.href = 'https://pagsmilebank.com.br/'
        return false
      }
      this.selectedLang = value.code || 'en'
      this.dropDownDisplayText = (value.code || 'EN').toUpperCase()
      zenDeskFunc(value.code)
    },
    resetMobileActive () {
      /* console.log('resetMobileActive', this.mobileActive) */
      this.mobileActive = []
    },
    setLang (value) {
      this.selectedLang = value.code || 'en'
      this.dropDownDisplayText = (value.code || 'EN').toUpperCase()
    },
    menuHover (opt) {
      this.hideMenus()
      if (opt === 1) {
        this.overMenu = true
        this.solutionsHover = true
      } else if (opt === 2) {
        this.overMenu = true
        this.companyHover = true
      } else if (opt === 3) {
        this.overMenu = true
        this.contactHover = true
      }
    },
    changePage (event) {
      // console.log('changeSection', event)
      if (event !== null && event !== this.currentPage) {
        this.currentPage = event
      }
    },
    hideMenus () {
      this.overMenu = false
      this.solutionsHover = false
      this.companyHover = false
      this.contactHover = false
      this.globeHover = false
    },
    closeNotification () {
      this.notification.closed = true
      setTimeout(() => {
        this.notification.enabled = false
      }, 550)
    },
    checkGeoLocation (code) {
      // console.log('checkGeoLocation', code, this.geoInfo)
      if (code === 'en') {
        const { countryCode } = this.geoInfo || {}
        const { locale } = this.$i18n
        if (countryCode && countryCode === 'BR' && locale !== 'en') {
          this.$i18n.locale = 'en'
          if (this.sidebarActive) {
            this.sidebarActive = false
          }
        }
      }
    },
    setGeoInfo (value) {
      // console.log('setGeoInfo', value)
      this.geoInfo = value
      const { countryCode } = value || {}
      if (countryCode === 'BR') {
        this.onChangeLang(null, { code: 'pt' })
      }
    },
    iconsHovering (index, value) {
      if (typeof index === 'number' && typeof value === 'boolean') {
        const newValue = this.iconsHover
        newValue[index] = value
        this.iconsHover = [...newValue]
      }
    },
    async fetchEvent () {
      const uuid = uuidv4().replace(/-/g, '')
      const eventResponse = await fetch(
        `${process.env.APIURL}/website/common/event/list`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            key: this.$encrypt(uuid),
            data: esaEncryptData(
              JSON.stringify({ page_size: 999, page_num: 1 }),
              uuid
            )
          })
        }
      )
        .then(res => res.json())
        .catch(function (e) {
          console.log(e)
        })
      const { data, key } = eventResponse
      const preResult = esaDecryptData(data, this.$decrypt(key))
      const events = JSON.parse(preResult).list
      const pinEvent = events.filter(event => event.pin === 1)
      if (pinEvent.length > 0) {
        this.notification = {
          enabled: !(window.location.origin.includes('808')),
          title: pinEvent[0].title,
          description: pinEvent[0].description,
          link: `/about-us/events/${pinEvent[0].id}`,
          closed: false
        }
      }
    }
  }
}
